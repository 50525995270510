import React from "react";

import "./Menu.scss";

const Menu = props => {
	const { handleClickMenu, status } = props;

	const items = props.items.map(item => (
		<li key={item.id}>
			<a href={"#" + item.id} id={item.id} onClick={handleClickMenu}>
				{item.name}
			</a>
		</li>
	));

	return (
		<div className={"menu " + status}>
			<ul>
				{items}
				<li><a href="./WaardenkaartZorg.pdf" download>Download als pdf</a></li>
			</ul>
		</div>
	);
};

export default Menu;
