import React from 'react';

import Kruis from '../Icons/Kruis';

import './Modal.scss';

const Modal = (props) => {

  return (
  		<div className={"modal " + props.status}>
        <div className="modal-overlay" onClick={props.handleClickModal}></div>
        <div className="modal-popup">
        	<h4 className="modal-title">
        		{props.content.title}
        		<button className="modal-close" onClick={props.handleClickModal}>
        			<Kruis />
      			</button>
      		</h4>
        	<div className="modal-content">{props.content.text}</div>
        </div>
       </div>
    );
}

export default Modal;
