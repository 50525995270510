import React, { Component } from 'react';

import Header from '../../components/Header/Header';
import Page from '../../components/Page/Page';
import Modal from '../../components/Modal/Modal';
import Footer from '../../components/Footer/Footer';

import { menuItems, modalItems } from '../../data/text.js';
import waarden from '../../data/waardenkaart.js';

import './App.scss';

class App extends Component {
		constructor(props) {
				super(props);
				this.state = {
						waarden,
						menuItems,
						menuStatus: 'closed',
						modalContent: modalItems.uitleg,
						modalStatus: 'closed',
				}
		}

	handleClickHamburger= event => {
		let status = (this.state.menuStatus === "closed") ? "open" : "closed";
		this.setState({ menuStatus: status});
	}

	handleOpenModal= event => {
		event.preventDefault();
		const body = document.getElementsByTagName("body")[0];
		body.style.overflow = "hidden";
		this.setState({
			modalStatus: 'open',
			modalContent: modalItems[event.target.id],
			menuStatus: 'closed'
		});
	}

	handleClickModal = () => {
		const body = document.getElementsByTagName("body")[0];
		body.style.overflow = "inherit";
		this.setState({
			modalStatus: 'closed',
		});
	}

	render() {

		const appClass = (this.state.modalStatus === 'open') ? 'app modalOpen' : 'app';

		return (
			<div className={appClass}>
				<Header
					title="Waardenkaart van de gezondheidszorg"
					items={this.state.menuItems}
					status={this.state.menuStatus}
					handleClickHamburger={this.handleClickHamburger}
					handleOpenModal={this.handleOpenModal}
				/>
				<Page
					handleOpenModal={this.handleOpenModal}
					data={this.state.waarden}
				/>
				<Modal
					content={this.state.modalContent}
					status={this.state.modalStatus}
					handleClickModal={this.handleClickModal}
				/>
				<Footer />
			</div>
		);
	}
}

export default App;
