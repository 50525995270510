import React from 'react';
import Twitter from '../Icons/Twitter';
import Facebook from '../Icons/Facebook';
import Mail from '../Icons/Mail';
import LinkedIn from '../Icons/LinkedIn';

import './Social.scss';

const Social = props => {

	const {url, text, title, message, test } = props;
	const clickHandler = (event) => {
		if (test) {
			event.preventDefault();
			alert('In de testversie is delen nog niet mogelijk');
		}
	}

	return (
		<div className="social">

			<a onClick={clickHandler}
					href={"https://twitter.com/intent/tweet/?text=" + text + "&url=" + url }
					target="_blank"
					rel="noopener noreferrer"
					aria-label="Deel via Twitter.">
					<Twitter />
			</a>

			<a onClick={clickHandler}
					href={"https://facebook.com/sharer/sharer.php?u=" + url}
					target="_blank"
					rel="noopener noreferrer"
					aria-label="Deel via Facebook.">
			    <Facebook/>
			</a>

			<a onClick={clickHandler}
					href={"https://www.linkedin.com/shareArticle?mini=true&title=" + title+ "&summary=" + text + "&url=" + url}
					target="_blank"
					rel="noopener noreferrer"
					aria-label="Deel via LinkedIn">
			  	<span className="socialbutton linkedin">
			  		<LinkedIn />
			  	</span>
	  	 </a>

			<a onClick={clickHandler}
					href={"mailto:?subject=" + text + "&body=" + message}
					target="_self"
					rel="noopener noreferrer"
					aria-label="Deel via e-mail.">
				<Mail />
			</a>

		</div>
  )
}

export default Social;
