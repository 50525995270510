import React from 'react';

const Mail = () => (
	<svg viewBox="0 0 48 48" className="icon mail">
		<path d="M11.83,13.92a1.67,1.67,0,0,1,.36,0H35.81a1.67,1.67,0,0,1,.36,0L24,24.57ZM24.56,26.32a.84.84,0,0,1-1.11,0L10.58,15.07a1.66,1.66,0,0,0-.08.49V32.44a1.69,1.69,0,0,0,1.69,1.69H35.81a1.69,1.69,0,0,0,1.69-1.69V15.56a1.66,1.66,0,0,0-.08-.49Z"/>
	</svg>
);

export default Mail;

