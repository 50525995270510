import React, { Component } from "react";
import { Flipper, Flipped } from "react-flip-toolkit";

import Kruis from "../Icons/Kruis";

import "./Map.scss";

class Map extends Component {
	state = { openTile: "" };

	openTile = evt => {
		evt.preventDefault();
		const openTile = evt.target.id;
		this.setState({ openTile });
	};

	closeTile = evt => {
		evt.preventDefault();
		const openTile = "";
		this.setState({ openTile });
	};

	render() {
		const { openTile } = this.state;
		const { data } = this.props;

		const tiles = data.items.map(tile => {
			const currentTile = tile.text.toLowerCase() === openTile;
			const values = tile.items.map(value => {
				let bulletlist, bullets;
				if (currentTile) {
					bullets = value.items.map((bullet, i) => (
						<li key={"bullet-" + value.text + "-" + i}>{bullet.text}</li>
					));
					bulletlist = <ul className="bullets">{bullets}</ul>;
				}
				return (
					<li key={"waarde-" + value.text}>
						<span>{value.text}</span>
						{bulletlist}
					</li>
				);
			});
			let className = "cluster " + tile.text.toLowerCase();
			if (currentTile) className += " open";
			const flipID = "cluster-" + tile.text;
			return (
				<Flipped flipId={flipID} key={flipID}>
					<div
						className={className}
						id={tile.text.toLowerCase()}
						onClick={this.openTile}
					>
						<button className="button-close-cluster" onClick={this.closeTile}>
							<Kruis />
						</button>
						<h4>{tile.text}</h4>
						<ul>{values}</ul>
					</div>
				</Flipped>
			);
		});

		const classNameGrid =
			openTile === "" ? "grid" : "grid grid-open grid-" + openTile;

		return (
			<div className="Map">
				<h2>Welke waarden vinden mensen belangrijk in de gezondheidszorg?</h2>
				<Flipper flipKey={this.state.openTile}>
					<div className={classNameGrid}>{tiles}</div>
				</Flipper>
			</div>
		);
	}
}

export default Map;
