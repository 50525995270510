import React from 'react';

import Social from '../Social/Social';
import LogoFabriek from '../Icons/LogoFabriek';

import './Footer.scss';

const Footer = props => {

		return (
		  <div className="footer">
			  <a href="https://www.argumentenfabriek.nl/" target="_blank" rel="noopener noreferrer">
			  	<LogoFabriek />
		  	</a>
		  	<Social
					url="https://etalage.argumentenfabriek.nl/waardenkaart-zorg"
					title="De Waardenkaart van de Nederlandse gezondheidszorg"
					text="Welke waarden vinden mensen belangrijk in de zorg?"
					message="Vind het antwoord op https://etalage.argumentenfabriek.nl/waardenkaart-zorg"
		  	 />
		  </div>
		)
}

export default Footer;
