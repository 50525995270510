import React from 'react';

export const menuItems = [
	{
		id: "uitleg",
		name: "Waarom waarden?"
	},
	{
		id: "model",
		name: "Een model"
	},
	{
		id: "colofon",
		name: "Colofon"
	}
];

export const modalItems = {
	uitleg: {
		title: 'Waarom denken over waarden in de zorg?',
		text: <div>
					<p>De gezondheidszorg is een van de populairste onderwerpen van het maatschappelijk debat in Nederland. Hoe hoog mag het eigen risico zijn? Hoe krijgen we de zorg voor ouderen op orde? Wat mogen ‘dure’ medicijnen kosten? En hoe roepen we een halt toe aan de bureaucratische rompslomp? Deze vragen domineren de politiek, de media en het werk van de 1,5 miljoen Nederlanders die in en om de zorg werken.</p>
					<p>We vinden de zorg van groot belang. Maar wat zit er onder al die over elkaar heen tuimelende overtuigingen? Wat zijn de onderliggende waarden op basis waarvan we debatteren over de vragen hierboven? Zou het niet goed zijn als we daar beter zicht op zouden krijgen?</p>
					<p>Dat zou zeker goed zijn, concludeert de Raad voor de Volksgezondheid en Samenleving onlangs nog in haar Zorgagenda; ‘Investeren in een gezonde samenleving vanuit een perspectief van opbrengsten vergt allereerst een politiek maatschappelijk gesprek over de gedeelde waarden die aan investeringen ten grondslag liggen.’</p>
					<h6>Stevig doorvragen</h6>
					<p>Welke waarden zijn dat dan? Kunnen we die niet in kaart brengen? Met deze vragen kwam Zorgverzekeraars Nederland in de zomer van 2016 bij De Argumentenfabriek.</p>
					<p>Het in kaart brengen van waarden is op zich al waardevol. Met waarden proberen mensen te verwoorden wat ze zich wensen voor ideale wereld of hoe mensen zich in een ideale wereld zouden gedragen. Inzicht hebben in welke waarden het maatschappelijk debat bepalen, is een belangrijke eerste stap in het vergroten van de sensitiviteit voor de pluriformiteit aan waarden in de samenleving. Een Waardenkaart maakt het ook makkelijker de diverse waardenvoorkeuren te herkennen die horen bij politieke stromingen of bij de specifieke rollen van de partijen in de zorg.</p>
					<p>Met de term waarden doelen we dan op ‘grote woorden’, als solidariteit, zelfbeschikking of de onaantastbaarheid van het menselijke lichaam. Het zijn deze concepten die onze stand­punten bepalen en die richting geven bij de keuzes die we maken. Het in kaart brengen van waarden geeft inzicht in deze veelal onbewuste voorkeuren en helpt zo het niveau van de publieke discussie over de gezondheidszorg te verhogen.</p>
					<p>Wie op zoek wil naar de onderliggende waarden, moet gesprekken voeren en daarbij dan stevig doorvragen. Waarom vind je dat belangrijk? Waarom? Waarom? Doorvragen, dat is wat Zorgverzekeraars Nederland is gaan doen. In een groot aantal denksessies, gesprekken en groepsinterviews heeft het team van René Groot Koerkamp ruim tweehonderd mensen het hemd van het lijf gevraagd; basisschoolleerlingen, patiënten, gezonde burgers, verpleegkundigen, psychologen, artsen, bestuurders in de zorg, professoren en tenslotte de leden van het Platform Zó werkt de zorg.</p>
					<p>Steeds waren dit geanimeerde bijeenkomsten. Want, hoewel we niet gewend zijn om waarden onder woorden te brengen, vinden mensen het fijn de tijd te krijgen om samen na te denken over wat nou écht van belang is.</p>
					<p>De ruwe opbrengst van de bijeenkomsten heeft Zorg­verzekeraars Nederland samen met De Argumentenfabriek gestructureerd, volgens de methode van de fabriek. Deze Waardenkaart Zorg, is het resultaat. We danken alle deelnemers voor hun denkwerk en hopen dat deze inventari­satie iedereen die op een fundamenteel niveau wil denken en discussiëren over de zorg een bruikbaar vocabulaire biedt.</p>
					<p>Kees Kraaijeveld , Juni 2017</p>
			</div>
		},
	model: {
		title: 'Het model: een waardenmatrix met negen clusters',
		text:
		<div>
				<p>Wat vinden mensen belangrijk in de zorg? Daarmee vragen we naar een kleurrijk pallet aan wensen. Mensen willen dat de zorg ze beter maakt, als ze ziek zijn. Ze willen een deskundige dokter waarop ze kunnen vertrouwen en ze willen respectvol worden behandeld.</p>
				<p>Tegelijkertijd willen mensen iets te kiezen hebben. Ze willen minstens zulke goede zorg als de buurman. Ze willen er niet te veel voor betalen en ze willen niet dat er in de zorg, inmiddels de grootste sector van Nederland, met de grootste banengroei, te veel geld wordt uitgegeven.</p>
				<p>Wie op zoek gaat naar waarden in de zorg, ziet al gauw door de bomen het bos niet meer. Wat dan houvast biedt, zijn de op empirisch onderzoek gestoelde waardenmodellen die psycho­ logen en sociologen de afgelopen decennia hebben ontwikkeld.</p>
				<h6>Morele psychologie</h6>
				<p>Bij De Argumentenfabriek maken we voor onze Waarden­kaarten gebruik van de zes morele waardenclusters van de Amerikaanse psycholoog Jonathan Haidt. Waardenclusters zijn verzamelingen van min of meer gelijksoortige waarden. Het zijn voor Haidt de universele morele fundamenten waarop menselijke morele voorkeuren zich ontwikkelen. Zoals we onze smaak vormen op basis van de vijf smaakgebieden op onze tong, zo vormen waardenvoorkeuren zich vanuit deze waardenclusters.</p>
				<p>Haidt onderscheidt drie meer ideële waardenclusters, Vrijheid, Rechtvaardigheid en Puurheid en drie, wat meer sociale waardenclusters; Loyaliteit, Hiërarchie en Empathie.</p>
				<p>Naast morele waarden streven mensen ook allerlei meer praktische waarden na. Denk aan het voorkomen van ziekten of het verlangen om schaarse middelen zo effectief mogelijk te besteden. Deze praktische waarden zijn ook te categoriseren. We onderscheiden bij de fabriek hierbij ook drie clusters.</p>
				<p>Het cluster Continuïteit bevat waarden over voortbestaan en welzijn. Het cluster Kwaliteit gaat over waarden die betrekking hebben op de kwaliteit van processen en eindproducten. In het cluster Nut staan de waarden die te maken hebben met nutsmaximalisatie en efficiëntie.</p>
				<p>Met de praktische waarden erbij komen we tot een waar­denmatrix met negen clusters, die we op de volgende pagina schematisch hebben weergegeven. De aard van deze clusters zullen we uitvoeriger beschrijven als we, verderop in de boek, per cluster de waarden beschrijven en de wijze waarop mensen in hun dagelijks taalgebruik uiting geven aan deze waarden als ze praten over de zorg.</p>
		</div>
	},
	colofon: {
		title: 'Wie heeft de Waardenkaart Zorg gemaakt?',
		text:
		<div>
				<p>De publicatie van deze Waardenkaart Zorg is het resultaat van de samenwerking tussen Zorgverzekeraars Nederland en De Argumentenfabriek. Daarbij komt de waardentheorie en de structuur primair van de fabriek. Zorgverzekeraars Nederland heeft de denksessies gedaan en het verdere onderzoek laten doen. Het team van René Groot Koerkamp heeft zo in totaal ruim tweehonderd mensen gevraagd wat zij nou het belang­rijkste vinden in de zorg. Dit waren basisschoolleerlingen, patiënten, gezonde burgers, verpleegkundigen, psychologen, artsen, bestuurders in de zorg en professoren.</p>
				<p>Heb je vragen of opmerkingen over de Waardenkaart Zorg? Stuur ons gerust een <a href="mailto:info@zowerktdezorg.nl">e-mail</a>, we stellen feedback zeer op prijs.</p>
	 </div>
	},

}
