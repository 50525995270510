import React from 'react';

const Hamburger = () => (
		<svg viewBox="0 0 44 44" className="icon hamburger">
			<line x1="13" y1="15" x2="31" y2="15" className="lijn1"  fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
			<line x1="13" y1="22" x2="31" y2="22" className="lijn2"  fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
			<line x1="13" y1="22" x2="31" y2="22" className="lijn3"  fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
			<line x1="13" y1="29" x2="31" y2="29" className="lijn4"  fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
		</svg>
		)

export default Hamburger;
