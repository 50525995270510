import React from "react";

import Map from "../Map/Map";

import "./Page.scss";

const Page = props => (

	<div className="Page">
		<Map {...props} />
	</div>

	)

export default Page;
