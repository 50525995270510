import React from 'react';

const Kruis = () => (
	<svg viewBox="0 0 44 44" className="icon kruis">
    <line className="lijn1" x1="30" y1="14" x2="14" y2="30" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" />
    <line className="lijn2" x1="14" y1="14" x2="30" y2="30" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" />
   </svg>
);

export default Kruis;

