import React from "react";

import Menu from "..//Menu/Menu";
import Hamburger from "../Icons/Hamburger";

import "./Header.scss";

const Header = props => {
	const {
		title,
		handleClickHamburger,
		items,
		status,
		handleOpenModal
	} = props;

	return (
		<div className="header-wrapper">
			<div className="header">
			<h1>{title}</h1>
			<button className="hamburgerbutton" onClick={handleClickHamburger}>
				<Hamburger />
			</button>
			</div>
			<Menu
				items={items}
				status={status}
				handleClickMenu={handleOpenModal}
			/>
		</div>
	);
};

export default Header;
